
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "",
          key: "logo",
          sortable: true,
        },
        {
          name: "Short Name",
          key: "short_name",
          sortable: true,
        },
        {
          name: "Full Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Address",
          key: "address",
          sortable: true,
        },
        {
          name: "Phone",
          key: "phone",
          sortable: true,
        },
        {
          name: "Email",
          key: "mail",
          sortable: true,
        },
        {
          name: "Website",
          key: "website",
          sortable: true,
        },
      ],
      tableData: [
        {
          id: 1,
          short_name: "ABC",
          name: "A.B.C Bangladesh-Turkish Technical School",
          address: "Nazirhat Municipality ,Fatickchari ",
          phone: "0312552697 ",
          mail: "abcbdtktschool@yahoo.com",
          website: "www.doulatpurabchighschool.edu.bd ",
        },
        {
          id: 2,
          short_name: "ACA",
          name: "ACA Technical Institute ",
          address: "Faterpur, Enayetnagar, Matlab Uttor, Chandpur ",
          phone: "0312510677  ",
          mail: "	aca.seip@gmail.com ",
          website: "www.aca.org ",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
